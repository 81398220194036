import { animatePercentage } from './common.js'

$(document).ready(function () {
    let logoNum = 6
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        logoNum = 6
    }

    let html = '';
    for (let i = 0; i < logoNum; i++) {
        html += "<div class='school-logo-wrapper'><img class='school-logo' src='/images/school-logo-" + i + ".png'></div>"
    }
    $('#school-logo-table').html(html);

    html = '';
    for (let i = 0; i < logoNum; i++) {
        html += "<div class='school-logo-wrapper'><img class='school-logo' src='/images/uni-logo-" + i + ".png'></div>"
    }
    $('#uni-logo-table').html(html);

    //HOVER SCROLL EFFECT
    let rightRepeater, leftRepeater;

    $('#box-header-end').mouseover(function (event) {
        rightRepeater = setInterval(() => scrollBoxHeader(1), 10, 'linear');
    })

    $('#box-header-end').mouseout(function (event) {
        clearInterval(rightRepeater)
        stopLocationAnimation();
    })

    $('#box-header-start').mouseover(function (event) {
        leftRepeater = setInterval(() => scrollBoxHeader(-1), 10, 'linear');
    })

    $('#box-header-start').mouseout(function (event) {
        clearInterval(leftRepeater)
        stopLocationAnimation();
    })

    //DRAG SCROLL EFFECT
    drag($('#location-box-header-container'), $('#location-box-header'));
    drag($('.team-header-container'), $('#team-box-header'));

    // if (window.scrollY === 0) {
    //     $('.menu').addClass('top');
    // } else {
    //     $('.menu').removeClass('top');
    // }
    //
    // $('.menu').mouseenter(function () {
    //     if (window.scrollY === 0) {
    //         $(this).removeClass('top');
    //     }
    // });
    //
    // $('.menu').mouseleave(function () {
    //     if (window.scrollY === 0 && (!$('.menu').hasClass('toggled') && !$('.menu-drill.menu-regions').hasClass('toggled'))) {
    //         $(this).addClass('top');
    //     }
    // });
})

function drag(slider, elemToMove) {
    if (slider[0].id.includes('location')) {
        dragLocationMobile(slider, elemToMove)
    } else {
        dragMobile(slider, elemToMove);
    }

    let mouseDown = false;
    let startX, scrollLeft;

    slider.mousemove(function (e) {
        e.preventDefault();
        if (!mouseDown) {
            return;
        }
        const scroll = Math.floor((e.pageX - startX) / 5);
        elemToMove.animate({scrollLeft: '-=' + scroll}, 10);
    });

    slider.mousedown(function (e) {
        e.preventDefault()
        mouseDown = true;
        startX = e.pageX;
        scrollLeft = slider.scrollLeft;
    });
    slider.mouseup(function (e) {
        mouseDown = false;
        elemToMove.stop(true);
    });
    slider.mouseleave(function (e) {
        mouseDown = false;
        elemToMove.stop(true);
    });
}


let elemToMoveWidth = 0;

function dragLocationMobile(slider, elemToMove) {
    let mouseDown = false;
    let startX, scroll;
    if (elemToMoveWidth === 0) {
        elemToMoveWidth = elemToMove.get(0).scrollWidth
    }

    slider.on("touchmove", function (e) {
        e.preventDefault();
        if (!mouseDown) {
            return;
        }
        scroll = Math.floor((e.targetTouches[0].screenX - startX));
        const marginLeft = (-1) * (elemToMove.offset().left - slider.offset().left);
        if (scroll < 0) { //scroll to the left side
            if (marginLeft <= elemToMoveWidth - window.innerWidth) { //elemToMove scroll
                // console.log("prev: ", elemToMove.offset().left)
                let nextMargin = marginLeft - scroll
                // console.log(nextMargin, " ", elemToMoveWidth - window.innerWidth)

                if (nextMargin >= elemToMoveWidth - window.innerWidth) { //if it reaches the end
                    elemToMove.animate({marginLeft: (-1) * (elemToMoveWidth - window.innerWidth + 16)}, 0);
                } else {
                    elemToMove.animate({marginLeft: '+=' + scroll}, 0);
                }
                // console.log("next: ", elemToMove.offset().left)
            } else {  //glide the slider
                glide(slider, scroll)
            }
        } else {
            if (marginLeft >= 0) { //scroll to the right side
                let nextMargin = marginLeft - scroll
                // console.log("SCROLL TO THE RIGHT: ", nextMargin)
                if (nextMargin < 0) { //if it reaches the end
                    // console.log("GLIDE TO RIGHT")
                    elemToMove.animate({marginLeft: 0}, 0);
                    glide(slider, scroll)
                } else {
                    elemToMove.animate({marginLeft: '+=' + scroll}, 0);
                }
            }
        }
    });

    slider.on("touchstart", function (e) {
        mouseDown = true;
        startX = e.targetTouches[0].screenX;
    });
    slider.on("touchend", function (e) {
        mouseDown = false;
        //elemToMove.stop(true);
    });
    slider.on("touchclose", function (e) {
        mouseDown = false;
        //elemToMove.stop(true);
    });
}

function glide(slider, scroll) {
    let touchEnd = false
    // console.log(slider.offset().left, " ww: ", window.innerWidth / 1.5)
    let offset = Math.abs(slider.offset().left + scroll)
    if (offset <= window.innerWidth / 3) {
        // console.log(offset, " ", window.innerWidth / 1.5)
        // console.log("CRR OFFSET: ", offset)
        slider.stop(true)
        slider.animate({marginLeft: '+=' + scroll}, {duration: 0, easing: "linear"})
        // console.log(slider.offset().left)

        slider.on("touchend", function (e) {
            if (!touchEnd) {
                // console.log("END OFFSET: ", offset)
                slider.stop(true)
                slider.animate({marginLeft: '-=' + scroll}, {duration: 0, easing: "linear"})
            }
            touchEnd = true
        });

        // setTimeout(() => {
        //     slider.animate({marginLeft: '-=' + scroll}, 0)
        // }, 200)
    }
}

function dragMobile(slider, elemToMove) {
    let mouseDown = false;
    let startX, scrollLeft;

    slider.on("touchmove", function (e) {
        e.preventDefault();
        if (!mouseDown) {
            return;
        }
        const scroll = Math.floor((e.targetTouches[0].screenX - startX) / 5);
        elemToMove.animate({scrollLeft: '-=' + scroll}, 10);
    });

    slider.on("touchstart", function (e) {
        mouseDown = true;
        startX = e.targetTouches[0].screenX;
        scrollLeft = slider.scrollLeft;
    });
    slider.on("touchend", function (e) {
        mouseDown = false;
        elemToMove.stop(true);
    });
    slider.on("touchclose", function (e) {
        mouseDown = false;
        elemToMove.stop(true);
    });
}

$(window).scroll(function (event) {
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + $(window).height();

    animatePercentage(docViewTop, docViewBottom)
    scrollHorizontalLogos(event, docViewTop, docViewBottom)

    // if (window.scrollY === 0 && (!$('.menu').hasClass('toggled') && !$('.menu-drill.menu-regions').hasClass('toggled'))) {
    //     $('.menu').addClass('top');
    // } else {
    //     $('.menu').removeClass('top');
    // }
})

function scrollHorizontalLogos(event, docViewTop, docViewBottom) {
    let offset = 5 * (checkScrollSpeed() / 10);
    const horizontalLogosTop = $('.horizontal-logos-container').offset().top;

    if (docViewTop <= horizontalLogosTop && horizontalLogosTop + 200 <= docViewBottom) {
        $('#scrollable-logos').animate({scrollLeft: '+=' + offset}, 1);
    }
}

var checkScrollSpeed = (function (settings) {
    settings = settings || {};

    var lastPos, newPos, timer, delta,
        delay = settings.delay || 50; // in "ms" (higher means lower fidelity )

    function clear() {
        lastPos = null;
        delta = 0;
    }

    clear();

    return function () {
        newPos = window.scrollY;
        if (lastPos != null) { // && newPos < maxScroll
            delta = newPos - lastPos;
        }
        lastPos = newPos;
        clearTimeout(timer);
        timer = setTimeout(clear, delay);
        return delta;
    };
})();

function scrollBoxHeader(sign) {
    const offset = 2;
    if (sign > 0) {
        $('#location-box-header').animate({scrollLeft: '+=' + offset}, 1);
    } else {
        $('#location-box-header').animate({scrollLeft: '-=' + offset}, 1);
    }

    stopLocationAnimation();
}

function stopLocationAnimation() {
    $('#location-box-header').stop(true, true);
}
