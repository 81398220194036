import intlTelInput from "intl-tel-input";
import utils from "intl-tel-input/build/js/utils";

const toResize = ['blog', 'schools-index', 'guides', 'media', 'newsletter']
document.cookie = "screenWidth=" + window.outerWidth + ";path=/;"

$(document).ready(function () {
        setVerticalTriangle()

        if (window.innerWidth < 1025) {
            const subEmailInputPadding = $('#subscribe-button').innerWidth() + 15;
            $('#email-input').attr("style", function (i, s) {
                return (s || '') + "padding-right: " + subEmailInputPadding + "px !important;"
            })
        }

        window.addEventListener('resize', function () {
            if (window.innerWidth > 1025) {
                setVerticalTriangle()
            }
        })

        var modal = document.getElementsByClassName("meet-our-consultants");
        var btn = document.getElementsByClassName("meet-our-consultants-btn");
        var span = document.getElementsByClassName("close")[0];
        if (btn != null) {

            $(".meet-our-consultants-btn").on('click', function () {
                modal[0].style.display = "block";
            })

            if (span != null)
                span.onclick = function () {
                    modal[0].style.display = "none";
                }

            $(".meet-our-consultants").on('click', function () {
                if (event.target.id == "close-consultants")
                    modal[0].style.display = "none";
            })

        }
        var modal_register = document.getElementById("news&resources-register");
        var btn_register = document.getElementsByClassName("news-resources-register-button");
        var span_register = document.getElementsByClassName("close_register")[0];
        // if (btn_register != null) {
        //
        //     $(".news-resources-register-button").on('click', function () {
        //         modal_register.style.display = "block";
        //     })
        //     if (span_register != null)
        //         span_register.onclick = function () {
        //             modal_register.style.display = "none";
        //         }
        //     window.onclick = function (event) {
        //         if (event.target == modal_register) {
        //             modal_register.style.display = "none";
        //         }
        //     }
        // }

        var modal_pay = document.getElementById("news&resources-pay");
        var btn_pay = document.getElementsByClassName("news-resources-pay-button");
        var span_pay = document.getElementsByClassName("close_pay")[0];
        if (modal_pay != null) {

            $(".news-resources-pay-button").on('click', function () {
                modal_pay.style.display = "block";
            })

            if (span_pay != null)
                span_pay.onclick = function () {
                    modal_pay.style.display = "none";
                }
            window.onclick = function (event) {
                if (event.target == modal_pay) {
                    modal_pay.style.display = "none";
                }
            }
        }

        $('.filter-sort-container #sort-dropdown').click(hoverOrClickFilter).hover(hoverOrClickFilter)
        $('.filter-sort-container #sort-dropdown .dropdown-menu').mouseleave(function () {
            $('.filter-sort-container #sort-dropdown button').css('visibility', 'visible')
        })

        let testURL = encodeURIComponent('http://1671-84-117-50-126.ngrok.io/' + 'uk/news/blog/39')
        let postURL = encodeURIComponent(window.location.href)

        $('#share-blog-facebook').click(() => shareToFacebook(postURL))
        $('#share-blog-linkedin').click(() => shareToLinkedin(postURL))
        $('#share-blog-twitter').click(() => shareToTwitter(postURL))


        var modal_webinar = document.getElementsByClassName("webinar-page-modal");
        var btn_webinar = document.getElementById("webinar-page-modal-button");
        var span_webinar = document.getElementsByClassName("close_webinar")[0];
        if (btn_webinar != null) {
            btn_webinar.onclick = function () {
                modal_webinar[0].style.display = "block";
            }

            if (span_webinar != null)
                span_webinar.onclick = function () {
                    modal_webinar[0].style.display = "none";
                }

            $(".webinar-page-modal").on('click', function () {
                if (event.target.id == "close-webinar")
                    modal_webinar[0].style.display = "none";
            })
        }

        var modal_carfax_education = document.getElementById("carfax-education-modal");
        var btn_carfax_education = document.getElementsByClassName("carfax-education-modal-button");
        var span_carfax_education = document.getElementsByClassName("close_carfax-education")[0];
        if (btn_carfax_education != null) {
            $(".carfax-education-modal-button").on('click', function () {
                modal_carfax_education.style.display = "block";
            })
            if (span_carfax_education != null)
                span_carfax_education.onclick = function () {
                    modal_carfax_education.style.display = "none";
                }
            window.onclick = function (event) {
                if (event.target == modal_carfax_education) {
                    modal_carfax_education.style.display = "none";
                }
            }
        }
        // var modal_carfax_education_enquiry = document.getElementsByClassName("carfax-education-modal-enquiry");
        // var btn_carfax_education_enquiry = document.getElementsByClassName("carfax-education-modal-button-enquiry");
        // var span_carfax_education_enquiry = document.getElementsByClassName("close_carfax-education-enquiry")[0];
        // if (btn_carfax_education_enquiry != null) {
        //
        //     $(".carfax-education-modal-button-enquiry").on('click', function () {
        //         location.href='get-in-touch';
        //     })
        //     if (span_carfax_education_enquiry != null)
        //         span_carfax_education_enquiry.onclick = function () {
        //             modal_carfax_education_enquiry[0].style.display = "none";
        //         }
        //
        //     $(".carfax-education-modal-enquiry").on('click', function () {
        //         if (event.target.id == "close-enquiry")
        //             modal_carfax_education_enquiry[0].style.display = "none";
        //     })
        //
        // }

        const idTemplate = '#modal-'
        let selected_id = idTemplate
        const profile_modal = document.getElementsByClassName("profile-pop-up");

        if (profile_modal != null) {
            $(".profile").on('click', function () {
                selected_id += $(this).attr('id')

                if ($(selected_id).length > 0) {
                    event.stopPropagation();
                }

                $(selected_id).show()
            })

            $(".close-profile-pop-up").on('click', function () {
                $(selected_id).hide();
                selected_id = idTemplate
            })

            $('.modal-content-profile').on('click', function () {
                event.stopPropagation();
            })

            $(window).click(function () {
                $(selected_id).hide();
                selected_id = idTemplate
            })

        }

        window.onpageshow = function (event) {
            let path = window.location.pathname.split('/')
            if (event.persisted && toResize.includes(path[path.length - 1])) {
                window.location.reload();
            }
        }

        document.cookie = "screenWidth=" + window.outerWidth + ";path=/;"
        window.addEventListener('resize', function (event) {
            let path = window.location.pathname.split('/')
            if (toResize.includes(path[path.length - 1]) || toResize.includes(path[path.length - 2])) {
                document.cookie = "screenWidth=" + window.outerWidth + ";path=/;"
                window.location.reload()
            }
        }, true);

        var link = document.getElementsByClassName("agent-header")
        if (link.length != 0) {
            $(".agent-header").on('click', function () {
                for (let i = 0; i < link.length; i++) {
                    if (link[i].classList.contains("active")) {
                        link[i].classList.remove("active");
                    }
                }
            })
        }

        let school_index_buttons = document.getElementsByClassName('school-index-button');
        if (school_index_buttons.length !== 0) {

        }

        // $('#submit-enquiry').unbind().on('click', function (e) {
        //     e.preventDefault();
        //
        //     let $form = $('form#enquiry-form');
        //
        //     $.ajax({
        //         url: $form.attr('action'),
        //         type: 'post',
        //         dataType: 'json',
        //         data: $form.serialize(),
        //         success: function (data) {
        //             $form.find('#status').html('<p class="alert alert-success">' + data.message + '</p>');
        //         },
        //         error: function (data) {
        //             $form.find('#status').html('<p class="alert alert-danger">' + data.message + '</p>');
        //         }
        //     });
        // })

        // $('.submit-get-in-touch').unbind().on('click', function (e) {
        //     e.preventDefault();
        //
        //     let $form = $('form#get-in-touch-form');
        //
        //     $.ajax({
        //         url: $form.attr('action'),
        //         type: 'post',
        //         dataType: 'json',
        //         data: $form.serialize(),
        //         success: function (data) {
        //             $form.find('#status').html('<p class="alert alert-success">' + data.message + '</p>');
        //         },
        //         error: function (data) {
        //             $form.find('#status').html('<p class="alert alert-danger">' + data.message + '</p>');
        //         }
        //     });
        // })

        if ($('#phone_number').length) {
            var phone = document.querySelector("#phone_number")
            var initPhone = intlTelInput(phone, {
                hiddenInput: "full_phone",
                initialCountry: $('#phone_country').val() !== '' ? $('#phone_country').val() : 'GB',
                separateDialCode: true,
                utilsScript: utils
            });

            $('#phone_country').val(initPhone.getSelectedCountryData().iso2);

            $('#phone_number').on('countrychange', function (e) {
                $('#phone_country').val(initPhone.getSelectedCountryData().iso2);
            });
        }

        if ($('#get-in-touch-phone_number').length) {
            var phone = document.querySelector("#get-in-touch-phone_number")
            var initPhone = intlTelInput(phone, {
                hiddenInput: "full_phone",
                initialCountry: $('#get-in-touch-phone_country').val() !== '' ? $('#get-in-touch-phone_country').val() : 'GB',
                separateDialCode: true,
                utilsScript: utils
            });

            $('#get-in-touch-phone_country').val(initPhone.getSelectedCountryData().iso2);

            $('#get-in-touch-phone_number').on('countrychange', function (e) {
                $('#get-in-touch-phone_country').val(initPhone.getSelectedCountryData().iso2);
            });
        }

        $('.status-alert .alert').delay(5000).slideUp(300);

        var form = document.getElementById("get-in-touch-form");

        if (form) {
            form.addEventListener('submit',
                function (event) {
                    event.preventDefault();
                    var captchResponse = $('#g-recaptcha-response').val();

                    if (captchResponse.length > 0) {
                        form.submit();
                    }
                });
        }

        $('#webinarsTab a[data-toggle="tab"]').on('click', function (e) {
            let url = e.currentTarget.getAttribute('data-link');
            window.history.pushState({page: "webinars"}, "Webinars", url);

        })
    }
)

function setVerticalTriangle() {
    const verticalTriangleHeight = $('.subscription-vertical-triangle-container').height();
    const borderBottomWidth = verticalTriangleHeight + "px";
    $('.vertical-triangle').css("border-bottom-width", borderBottomWidth)
}

let incremented = false;

export function animatePercentage(docViewTop, docViewBottom) {
    try {
        const percentageTop = $('#percentage-container').offset().top;

        if (!incremented && docViewTop <= percentageTop && percentageTop <= docViewBottom) {
            incremented = true;
            animateCounter()
        }
    } catch (err) {

    }
}

function animateCounter() {
    $('.counter').each(function () {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
        }, {
            duration: 4000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
}

function hoverOrClickFilter() {
    if (!$('.filter-sort-container #sort-dropdown .dropdown-menu').is(':visible')) { //hover or click will open the dropdown
        $('.filter-sort-container #sort-dropdown button').css('visibility', 'hidden')
    }
}

//use ngrok for localhost
function shareToFacebook(url) {
    // FB.ui({
    //     display: 'popup',
    //     method: 'share',
    //     href: 'http://22d4-84-117-50-126.ngrok.io/uk/news/blog/21'
    // }, function(response) {});

    let shareURL = "https://www.facebook.com/sharer.php?u=" + url;
    socialWindow(shareURL);
}

function shareToLinkedin(url) {
    let shareURL = "https://www.linkedin.com/shareArticle?mini=true&url=" + url; //'http://22d4-84-117-50-126.ngrok.io/uk/news/blog/21'
    socialWindow(shareURL);
    // $.ajax({
    //     url: 'https://api.linkedin.com/v2/ugcPosts',
    //     header: {
    //         'X-Restli-Protocol-Version': '2.0.0',
    //     },
    //     type: 'POST',
    //     data: {
    //         "author": "urn:li:person:8675309",
    //         "lifecycleState": "PUBLISHED",
    //         "specificContent": {
    //             "com.linkedin.ugc.ShareContent": {
    //                 "shareCommentary": {
    //                     "text": "Learning more about LinkedIn by reading the LinkedIn Blog!"
    //                 },
    //                 "shareMediaCategory": "ARTICLE",
    //                 "media": [
    //                     {
    //                         "status": "READY",
    //                         "description": {
    //                             "text": "Official LinkedIn Blog - Your source for insights and information about LinkedIn."
    //                         },
    //                         "originalUrl": "http://22d4-84-117-50-126.ngrok.io/uk/news/blog/21",
    //                         "title": {
    //                             "text": "Official LinkedIn Blog"
    //                         }
    //                     }
    //                 ]
    //             }
    //         },
    //         "visibility": {
    //             "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"
    //         }
    //     }
    // })
}

function shareToTwitter(url) {
    let tweet = encodeURIComponent($("meta[property='og:title']").attr("content"));
    let shareURL = "https://twitter.com/intent/tweet?url=" + url + "&text=" + tweet;
    socialWindow(shareURL);
}

function socialWindow(url) {
    let left = (screen.width - 570) / 2;
    let top = (screen.height - 570) / 2;
    let params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
    window.open(url, "NewWindow", params);
}
